import React from 'react'
import Banner from '../components/Banner';

import Layout from '../layout/Layout'
import MainQuote from '../components/Quote';

import Seo from "../layout/Seo"

const build = ({ pageContext }) => {

    const { locale } = pageContext;
    function Child({locale}){
        return (<>
        <Seo 
                title="Constructora en Bogotá" 
                description="Empresa de Construcción, Obra civil, Servicios de Arquitectura, Diseño y Acabados."
                pathname="https:localhost"
            />
            <Banner title={locale.build.banner.title} subtitle={locale.build.banner.subtitle} />
            <MainQuote title={locale.build.mainQuote} locale={locale}  />
            </>)
    }
    return (
        <Layout locale={locale} background="bg-image-build">
             {props => <Child {...props} />}
        </Layout>
    )
}

export default build